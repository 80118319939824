import React from 'react';
import logo from './logo.png';
import poster from './poster.jpg';
import './App.css';

function App() {
  return (
    <>
      <header className="mx-auto flex flex-wrap lg:flex-nowrap max-w-7xl items-center justify-center lg:justify-between p-6 lg:px-8">
        <div className="flex w-full items-center justify-center lg:justify-start lg:w-fit lg:flex-1">
          <a href="https://bingoplayernetwork.com" target="_blank" rel="noopener nofollow noreferrer" className="-m-1.5 p-1.5">
            <span className="sr-only">JatengToto</span>
            <img className="logo w-auto" src={logo} alt="Situs JatengToto"></img>
          </a>
        </div>
        <div className="flex w-full lg:w-fit items-center justify-center lg:flex-1 lg:justify-end">
          <div className="flex mt-2 lg:mt-0 items-center justify-between">
            <a href="https://rebrand.ly/skh2s8h?pg=register" target="_blank" rel="noopener nofollow noreferrer" className="text-dark bg-gradient-to-t from-yellow-600 to-yellow-300 hover:bg-yellow-300/90 focus:ring-4 focus:outline-none focus:ring-yellow-300/50 font-medium rounded-lg text-sm px-8 py-2.5 text-center inline-flex items-center me-2 mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 h-4 me-2" viewBox="0 0 16 16">
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
              </svg>
              Daftar
            </a>
            <a href="https://rebrand.ly/skh2s8h" target="_blank" rel="noopener nofollow noreferrer" className="text-dark bg-gradient-to-t from-red-600 to-amber-600 hover:bg-amber-600/90 focus:ring-4 focus:outline-none focus:ring-amber-600/50 font-medium rounded-lg text-sm px-8 py-2.5 text-center inline-flex items-center me-2 mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 h-4 me-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
              </svg>
              Login
            </a>
          </div>
        </div>
      </header>
      <main className="mx-auto flex flex-wrap lg:flex-nowrap max-w-7xl items-center justify-center lg:justify-between p-6 lg:px-8">
          <a href="https://rebrand.ly/skh2s8h" className="w-full" target="_blank" rel="noopener nofollow noreferrer">
            <img className="h-auto w-full max-w-full rounded-lg" src={poster} alt="JatengToto Hadiah Diskon Togel Terbesar"></img>
          </a>
      </main>
      <footer className="fixed bottom-0 left-0 z-50 w-full h-16 bg-gradient-gold border-t border-yellow-400">
          <div className="grid h-full max-w-7xl grid-cols-5 mx-auto font-medium">
              <a href="https://rebrand.ly/skh2s8h?pg=rules-game" target="_blank" rel="noopener nofollow noreferrer" className="inline-flex flex-col items-center justify-center px-5 border-x border-yellow-400 hover:bg-yellow-600 group">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 mb-2 text-yellow-300 group-hover:text-yellow-100" viewBox="0 0 16 16">
                    <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5z"/>
                    <path d="M4.268 1A2 2 0 0 1 6 0h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L13 13.768V2a1 1 0 0 0-1-1z"/>
                  </svg>
                  <span className="text-sm text-yellow-300 group-hover:text-yellow-100">Guide</span>
              </a>
              <a href="https://rebrand.ly/skh2s8h?pg=promotion" target="_blank" rel="noopener nofollow noreferrer" className="inline-flex flex-col items-center justify-center px-5 border-e border-yellow-400 hover:bg-yellow-600 group">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 mb-2 text-yellow-300 group-hover:text-yellow-100" viewBox="0 0 16 16">
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06"/>
                  </svg>
                  <span className="text-sm text-yellow-300 group-hover:text-yellow-100">Promo</span>
              </a>
              <a href="https://rebrand.ly/skh2s8h" target="_blank" rel="noopener nofollow noreferrer" className="inline-flex flex-col items-center justify-center px-5 border-e border-yellow-400 hover:bg-yellow-600 group">
                  <svg className="w-5 h-5 mb-2 text-yellow-300 group-hover:text-yellow-100" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
                  </svg>
                  <span className="text-sm text-yellow-300 group-hover:text-yellow-100">PlayNow</span>
              </a>
              <a href="https://heylink.me/LoginJatengtoto" target="_blank" rel="noopener nofollow noreferrer" className="inline-flex flex-col items-center justify-center px-5 border-e border-yellow-400 hover:bg-yellow-600 group">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 mb-2 text-yellow-300 group-hover:text-yellow-100" viewBox="0 0 16 16">
                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"/>
                  </svg>
                  <span className="text-sm text-yellow-300 group-hover:text-yellow-100">Alternatif</span>
              </a>
              <a href="https://direct.lc.chat/17743872" target="_blank" rel="noopener nofollow noreferrer" className="inline-flex flex-col items-center justify-center px-5 border-x border-yellow-400 hover:bg-yellow-600 group">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 mb-2 text-yellow-300 group-hover:text-yellow-100" viewBox="0 0 16 16">
                    <path d="M16 8c0 3.866-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                  </svg>
                  <span className="text-sm text-yellow-300 group-hover:text-yellow-100">ChatUs</span>
              </a>
          </div>
      </footer>
    </>
  );
}

export default App;
